//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dropdown from "./../components/Dropdown.vue";
import Logo from "../components/Logo.vue";

export default {
  name: "Header",
  components: {
    Dropdown,
    Logo,
  },
  data: function () {
    return {
      mobileNavOpen: false,
      limitPosition: 70,
      scrolledUp: false,
      scrolledDown: false,
      lastPosition: 0,
      isHeaderBackgroundVisible: false,
      healthproviders: [
        {
          id: 1,
          productname: "Hospital Management System",
          link: "/products/hospital-management-system",
          category: "Health Providers",
        },
        {
          id: 2,
          productname: "Clinic Management System",
          link: "/products/clinic-management-system",
          category: "Health Providers",
        },
        {
          id: 3,
          productname: "Laboratory Management System",
          link: "/products/lab-management-system",
          category: "Health Providers",
        },
        {
          id: 4,
          productname: "Pharmacy Management System",
          link: "/products/pharmacy-management-system",
          category: "Health Providers",
        },
      ],
      payers: [
        {
          id: 1,
          productname: "Health Claim Management System",
          link: "/products/health-claim-management-system",
          category: "Payers & Insurers",
        },
        {
          id: 2,
          productname: "Payor System",
          link: "/products/payor-system",
          category: "Payers & Insurers",
        },
      ],
      patients: [
        {
          id: 1,
          productname: "Patient/Member Portal",
          link: "/products/patient-member-portal",
          category: "Patients",
        },
      ],
    };
  },
  methods: {
    clickOutside(e) {
      if (
        !this.mobileNavOpen ||
        this.$refs.mobileNav.contains(e.target) ||
        this.$refs.hamburger.contains(e.target)
      )
        return;
      this.mobileNavOpen = false;
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return;
      this.mobileNavOpen = false;
    },

    handleScroll: function () {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY &&
        this.mobileNavOpen === false
      ) {
        // move down
        this.scrolledDown = true;
        this.scrolledUp = false;
      } else if (this.lastPosition > window.scrollY) {
        // move up
        this.scrolledDown = false;
        this.scrolledUp = true;

        if (this.lastPosition < this.limitPosition) {
          this.isHeaderBackgroundVisible = false;
        } else {
          this.isHeaderBackgroundVisible = true;
        }
      }

      this.lastPosition = window.scrollY;
      // this.scrolledDown = window.scrollY > limitPosition;
    },
  },

  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
  },
  mounted() {
    document.addEventListener("click", this.clickOutside);
    document.addEventListener("keydown", this.keyPress);
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutside);
    document.removeEventListener("keydown", this.keyPress);
  },
};
