//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from './Header.vue'

import Footer from './Footer.vue'

export default {
  name: 'Features',
  components: {
    Header,
 
    Footer,
  },
}
