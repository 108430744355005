import AOS from "aos";
import "aos/dist/aos.css";

// import { focusHandling } from 'cruip-js-toolkit'

export default ({ app }) => {
  app.aos = AOS.init({
    once: true,
    disable: false,
    duration: 600,
    startEvent: "load",
    animatedClassName: "aos-animate",
    anchorPlacement: "top-bottom"
    // easing: 'ease-out-quart',
  });
  // focusHandling()
  // // Route change
  // if (this.$nuxt.$route) {
  //   this.$watch('$route', () => {
  //     focusHandling('outline')
  //   });
  // }
};
