//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Dropdown',
  props: {
    title: {
      type: String,
      default: null,
      required: true
    }
  },
  data: function () {
    return {
      dropdownOpen: false
    }
  },
}
