export default {
  HeroHome: {
    title: {
      first: 'Digital',
      second: 'Healthcare',
      third: 'Platform'
    },
    subtitle: {
      first: 'Digital healthcare transformation, software development & consultancy for the healthcare industry.'
    }
  },
  Carousel: {
    title: {
      first: 'Let us help you with your digital healthcare journey',
    },
    subtitle: {
      first: 'We design, develop, test & deploy custom systems for the healthcare industry. We also offer consulting and auditing services.'
    }
  }
};